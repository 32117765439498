<template>
  <div class="page-container">
    <div class="banner">
      <img class="img" src="../../assets/news/detail-banner.png" alt="" />
      <div class="banner-title">
        <h2>{{t("company.news_detail.banner_title")}}</h2>
      </div>
    </div>
    <section class="sec-container" v-if="noData">
      <div class="d-header">
        <h2 class="title">{{t("company.news_detail.detail1.title")}}</h2>
        <div class="subtitle">
            <span>{{t("company.news_detail.detail1.date")}}</span>

            <a href="" title="">
                <img src="../../assets/news/pinglun.png" alt="">
            </a>
            <a href="">
                <img src="../../assets/news/fenxiang.png" alt="">
            </a>
        </div>
      </div>
      <div class="d-img-box">
          <img :src="require('../../assets/news/' + imgName)" alt="">
          <!-- <img src="../../assets/news/en-news-detail1.png" alt=""> -->
      </div>
    </section>
  </div>
</template>
<script>
import {ref} from 'vue'
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router"
import {news_detail} from "../../language/en";
export default {
    name: 'NEWSDETAIL',
    components: {},
    setup() {
        const { t } = useI18n();
        const route = useRoute();
        let noData = ref(true)
        let id = route.params.id
        let lang = JSON.parse(localStorage.getItem('current_lang')).name
        let imgName = ref(lang ? lang + '-news-' + 'detail' + id + '.png' : 'en-news-' + 'detail' + id + '.png')
        if (!news_detail['detail' + id]){
            noData = false
        }
        return {
          t,
          noData,
          imgName
        };
    },
}
</script>
<style lang="less" scoped>
.page-container {
  .banner {
    display: block;
    position: relative;
    z-index: 0;
    background: #000;
    overflow: hidden;
    height: 410px;
    width: 100%;
    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .banner-title {
      max-width: 1140px;
      margin: 0 auto;
      color: #F4F5F5;
      font-weight: 400;
      font-size: 60px;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      &::after {
        content: '';
        display: block;
        width: 140px;
        margin: 15px 0;
        border-bottom: 8px solid #FF9333;
        border-radius: 4px;
      }
    }
  }
  .sec-container {
    max-width: 1140px;
    margin: 50px auto;
    .d-header {
        border-bottom: 1px solid #ddd;
        .title {
            color: #FF9333;
            font-size: 22px;
        }
        .subtitle {
            font-size: 12px;
            color: #999;
            margin-bottom: 10px;
            a {
                float: right;
                margin-left: 20px;
                img {
                    width: 18px;
                }
            }
        }
    }
    .d-img-box {
        margin: 20px 0;
        img {
            width: 100%;
        }
    }
  }
}
</style>
